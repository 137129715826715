<template>
  <div>
    <div>
    <div class="search">
      <el-input placeholder="请输入资产编号查询" style="width: 200px; padding-right: 10px;" v-model="a"></el-input>
      <el-input placeholder="请输入资产名称查询" style="width: 200px; padding-right: 10px;" v-model="b"></el-input>
      <el-autocomplete style="width: 200px; padding-right: 10px;"
          class="inline-input"
          v-model="c"
          :fetch-suggestions="querySearch"
          placeholder="请输入资产分类查询"
          @select="handleSelect"
      ></el-autocomplete>
      <el-autocomplete style="width: 200px; padding-right: 10px;"
          class="inline-input"
          v-model="x"
          :fetch-suggestions="querySearch1"
          placeholder="请输入资产使用人查询"
          @select="handleSelect1"
      ></el-autocomplete>
      <el-button type="info" plain style="margin-left: 10px" @click="load(1)">查询</el-button>
      <el-button type="warning" plain style="margin-left: 10px" @click="reset">重置</el-button>
    </div>

    <div class="operation">
      <el-button type="primary" plain @click="dialogFormVisible=true">字段筛选</el-button>
      <el-button type="primary" plain @click="handleAdd123">导出报表</el-button>
      <el-button type="danger" plain @click="delBatch1">打印报表</el-button>
    </div>

  </div>
<!--
a 资产编号     b 资产名称     c 资产分类     d 国标分类     e 实有数量/面积     f 实有原值     g 实有累计折旧/摊销     h 盘点结果
i 使用状况     j 车辆用途     k 汽车排气量    l 坐落位置    m 产品序列号     n 账面数量/面积    o 账面价值     p 账面累计折旧/摊销
q 账面净值     r 取得方式     s 规格型号     t 取得日期     u 财务入账日期     v 价值类型     w 使用部门     x 使用人
y 存放地点     z 原资产编号     aa 备注
    -->
    <div class="table">
      <el-table :data="tableData" strip @selection-change="handleSelectionChange" stripe border :header-cell-class-name="'headerBg'" >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="序号" width="50" align="center"></el-table-column>
        <el-table-column prop="a" label="资产编号"  width="110" v-if="vShow.a"></el-table-column>
        <el-table-column prop="b" label="资产名称" :show-overflow-tooltip="true" v-if="vShow.b"></el-table-column>
        <el-table-column prop="c" label="资产分类" :show-overflow-tooltip="true"  v-if="vShow.c"></el-table-column>
        <el-table-column prop="d" label="国标分类" v-if="vShow.d"></el-table-column>
        <el-table-column  label="实有数量/面积" v-if="vShow.e">
        <template slot-scope="scope">
          <!-- 意思是：给这个div绑定动态样式，颜色color的属性值为getColorByNation()这个方法的返回值，所以只需要通过传过去的scope 对方法的返回值做动态设置即可  -->
          <div :class="scope.row.e ? 'cellcheck' : ''">{{ scope.row.e }}</div>
        </template>
        </el-table-column>
        <el-table-column prop="f" label="实有原值" v-if="vShow.f"></el-table-column>
        <el-table-column prop="g" label="实有累计折旧/摊销" v-if="vShow.g"></el-table-column>
        <el-table-column prop="h" label="盘点结果" v-if="vShow.h"></el-table-column>
        <el-table-column prop="i" label="使用状况" v-if="vShow.i"></el-table-column>
        <el-table-column prop="j" label="车辆用途" v-if="vShow.j"></el-table-column>
        <el-table-column prop="k" label="汽车排气量" v-if="vShow.k"></el-table-column>
        <el-table-column prop="l" label="坐落位置" v-if="vShow.l"></el-table-column>
        <el-table-column prop="m" label="产品序列号" v-if="vShow.m"></el-table-column>
        <el-table-column prop="n" label="账面数量/面积" v-if="vShow.n"></el-table-column>
        <el-table-column prop="o" label="账面价值" v-if="vShow.o"></el-table-column>
        <el-table-column prop="p" label="账面累计折旧/摊销" v-if="vShow.p"></el-table-column>
        <el-table-column prop="q" label="账面净值" v-if="vShow.q"></el-table-column>
        <el-table-column prop="r" label="取得方式" v-if="vShow.r"></el-table-column>
        <el-table-column prop="s" label="规格型号" v-if="vShow.s"></el-table-column>
        <el-table-column prop="t" label="取得日期" v-if="vShow.t"></el-table-column>
        <el-table-column prop="u" label="财务入账日期" v-if="vShow.u"></el-table-column>
        <el-table-column prop="v" label="价值类型" v-if="vShow.v"></el-table-column>
        <el-table-column prop="w" label="使用部门" v-if="vShow.w"></el-table-column>
        <el-table-column prop="x" label="使用人" v-if="vShow.x"></el-table-column>
        <el-table-column prop="y" label="存放地点" v-if="vShow.y"></el-table-column>
        <el-table-column prop="z" label="原资产编号" v-if="vShow.z"></el-table-column>
        <el-table-column prop="aa" label="备注" v-if="vShow.aa"></el-table-column>
        <el-table-column label="操作" align="center" width="220">
          <template v-slot="scope">
            <el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button>
<!--            <el-button size="mini" type="danger" plain @click="del(scope.row.id)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[5, 10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="字段筛选" :visible.sync="dialogFormVisible" @open="open">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkList">
        <div style="display: flex; flex-direction: row; flex-wrap: wrap">
            <div v-for="item in vShowMap" style="width: 23%; padding: 5px;">
              <el-checkbox :label="item[0]" :checked="item[1]">{{ item[2] }}</el-checkbox>
            </div>
        </div>
       </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="toDo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import user from "@/views/manager/system/User";

export default {
  name: "assetscheck",
  data() {
    return {
      a: '',
      b: '',
      c: '',
      x: '',
      vShow: {
        a: true,b: true,c: true,d: false,e: true,f: true,g: true,h: true,i: true,j: false,k: false,l: false,m: false,n: false,
        o: false,p: false,q: false,r: false,s: false,t: false,u: false,v: false,w: true,x: true,y: true,z: false,aa: true,
      },
      field: ['资产编号', '资产名称', '资产分类', '国标分类', '实有数量/面积', '实有原值', '实有累计折旧/摊销','盘点结果',
        '使用状况', '车辆用途', '汽车排气量', '坐落位置', '产品序列号', '账面数量/面积', '账面价值', '账面累计折旧/摊销',
        '账面净值', '取得方式', '规格型号', '取得日期', '财务入账日期', '价值类型', '使用部门', '使用人', '存放地点', '原资产编号', '备注',],
      vShowMap: [],
      dialogFormVisible: false,
      tableData: [],  // 所有的数据
      pageNum: 1,   // 当前的页码
      pageSize: 10,  // 每页显示的个数
      total: 0,
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      ids: [],
      checkList: [],
      checkAll: false,
      isIndeterminate: true,
      classify: [],
      users: [],
    }
  },
  created() {
    this.load(1)
    // 获取分类和使用人数组
    this.request.get('/assetscheck/getOptions/' + this.user.department ).then(res => {
      console.log(res.data)
      // this.classify = res.data.classify
      res.data.classify.forEach(item=>{
        this.classify.push({"value": item})
      })
      // this.users = res.data.users
      res.data.user.forEach(item=>{
        this.users.push({"value": item})
      })
    })
  },
  mounted() {
  },
  methods: {
    toDo(){ // 弹窗‘确定’关闭，给vShow赋值，{'a': true, 'b': 'true', ......}
       this.vShow =
          this.vShowMap.reduce((obj, item) => {
            obj[item[0]] = this.checkList.indexOf(item[0]) !== -1;
        return obj;
      }, {});
       this.dialogFormVisible = false
    },
    open(){   //el-dialog弹窗后，初始化vShowMap,[['a', true, '资产编号'], ['b', true, '资产名称'], ..........]
      var i=0
      this.vShowMap = Object.keys(this.vShow).map(key => {
        return [key, this.vShow[key], this.field[i++]];
      })
    },
    handleCheckAllChange(val) { // 全选或全消 checkBox项，
      this.checkList = [];
      var aar = []
      if(val){
        this.vShowMap.forEach(function(item) {
          aar.push(item[0]);
        });
      }
      this.checkList = aar;
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    handleExcel(){
      // const currentToken = sessionStorage.getItem('token')
      let consturl='/assetscheck/excel?department=河池市宜州区气象局';
      var xhr = new XMLHttpRequest();
      xhr.open("get", consturl, true); // get、post都可
      xhr.responseType = "blob";
      xhr.setRequestHeader("token", this.user.token); //加请求头
      xhr.onload = function() {
        if(xhr.status==200){
          let blob = new Blob([this.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var a = document.createElement("a")
          var url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = '家长通讯录'  // 文件名
        }
        a.click()
        window.URL.revokeObjectURL(url)
      }
      xhr.send();
    },
    handleEdit(row) {   // 编辑数据
    },
    del(id) {   // 单个删除
    },
    handleSelectionChange(rows) {   // 当前选中的所有的行数据
      this.ids = rows.map(v => v.id)
    },
    load(pageNum) {  // 分页查询
      if (pageNum) this.pageNum = pageNum
      this.request.get('/assetscheck/page', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          a: this.a,      // 资产编号
          b: this.b,      // 资产名称
          c: this.c,      // 资产分类
          x: this.x,      // 使用人
        }
      }).then(res => {
        console.log(res.data)
        this.tableData = res.data?.records
        this.total = res.data?.total
      })
    },
    reset() {
      this.a = this.b = this.c = this.x = ''
      this.load(1)
    },
    handleCurrentChange(pageNum) {
      this.load(pageNum)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=val
      this.load(1)
    },
    handleFileUploadSuccess(res) {
      console.log(res)
      this.$message.success("上传成功")
      this.load()
    },

    querySearch(queryString, cb) {
      var restaurants = this.classify;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item);
    },

    querySearch1(queryString, cb) {
      var restaurants = this.users;
      var results = queryString ? restaurants.filter(this.createFilter1(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter1(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect1(item) {
      console.log(item);
    },
  }

}
</script>

<style scoped>
.search{
padding: 0 20px 10px;
}
.operation{
  padding: 10px 20px;
  width: 100%;
  /*background: #b3d1eb;*/
}
.cellcheck{
  color: red;;
  font-weight: 900;
  text-align: center;
}
</style>
